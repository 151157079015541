import React from "react";
import {
	PartnersTitle,
	PartnersContainer,
	PartnersItem,
} from "./PartnersElements";

import BLPG from "../../images/partners/BLPG.png";
import IPH from "../../images/partners/IPH.png";
import BKracht from "../../images/partners/burgerkracht.png";
import Kerkrade from "../../images/partners/gemeente-kerkrade.png";
import Vie from "../../images/partners/Vie.png";
import Bibliotheek from "../../images/partners/Bibliotheek.png";

const Partners = () => {
	return (
		<>
			<PartnersTitle>
				<h1>Onze Partners</h1>
			</PartnersTitle>
			<PartnersContainer>
				<PartnersItem>
					<a
						href="https://limburgpositiefgezond.nl/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={BLPG} alt="" />
					</a>
				</PartnersItem>
				<PartnersItem>
					<a
						href="https://www.iph.nl/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={IPH} alt="" />
					</a>
				</PartnersItem>
				<PartnersItem>
					<a
						href="https://vie-kerkrade.nl/vie-innovatie-hub-parkstad/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={Vie} alt="" />
					</a>
				</PartnersItem>
				<PartnersItem>
					<a
						href="https://www.burgerkrachtlimburg.nl/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={BKracht} alt="" />
					</a>
				</PartnersItem>
				<PartnersItem>
					<a
						href="https://https://www.kerkrade.nl/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={Kerkrade} alt="" />
					</a>
				</PartnersItem>
				<PartnersItem>
					<a
						href="https://www.bibliotheekkerkrade.nl/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={Bibliotheek} alt="" />
					</a>
				</PartnersItem>
			</PartnersContainer>
		</>
	);
};

export default Partners;
