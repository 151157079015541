import styled from "styled-components";

export const InfoContainer = styled.div`
	box-sizing: border-box;
	max-width: 1024px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	align-self: center;
	padding: 4rem 1rem;
	margin: auto;

	gap: 16px;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`;

export const InfoBody = styled.div`
	flex: 1;
`;

export const InfoIllustration = styled.div`
	flex: 1;

	@media screen and (max-width: 820px) {
		width: 100%;
	}
`;
