import styled from "styled-components";

export const FeaturesTitle = styled.div`
	max-width: 1024px;
	margin: 0 auto;
	color: #000000;
`;

export const FeaturesContainer = styled.div`
	max-width: 1024px;
	padding-top: 32px;
	padding-bottom: 32px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	text-align: center;
	@media screen and (max-width: 820px) {
		width: 90%;
	}
`;

export const FeaturesItem = styled.div`
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
	width: 24%;
	display: flex;
	margin: 4px;
	text-align: center;
	justify-content: center;
	align-items: center;
	p {
		margin-left: 16px;
		margin-right: 16px;
	}
	@media (max-width: 860px) {
		width: 47%;
		height: auto;
		justify-content: center;
		align-items: center;
	}
`;
