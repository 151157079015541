import styled from "styled-components";

export const PartnersTitle = styled.div`
	max-width: 1024px;
	margin: 0 auto;
	color: #000000;
	@media screen and (max-width: 820px) {
		text-align: center;
		align-items: center;
		justify-content: center;
		margin: auto;
	}
`;

export const PartnersContainer = styled.div`
	max-width: 1024px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	@media screen and (max-width: 820px) {
		width: 90%;
		align-items: center;
		justify-content: center;
		margin: auto;
	}
`;

export const PartnersItem = styled.div`
	background: #fff;
	width: 162px;
	height: 100%;
	margin: 4px;
	padding: 8px;
	box-sizing: border-box;
	border-radius: 16px;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
	
	img {
		width: 100%;
		height: 100%;
		
	}

	@media (max-width: 860px) {
		width: 47%;
		
`;
