import styled from "styled-components";

export const ServicesContainer = styled.div`
	color: #fff;
	width: 100%;
	max-width: 1024px;
	margin: auto;
	padding: ${({ borderPosition, index }) => {
		const topPadding = index === 0 ? 100 + 32 : 100 - 32;
		return borderPosition === "left"
			? `${topPadding}px 0 0 200px`
			: `${topPadding}px 200px 0 0`;
	}};
	@media (max-width: 820px) {
		width: 90%;
		padding: ${({ borderPosition, index }) => {
			const topPadding = index === 0 ? 100 + 32 : 100 - 32;
			return borderPosition === "left"
				? `${topPadding}px 0 0 0`
				: `${topPadding}px 0 0 0`;
		}};
	}
`;

export const ServicesWrapper = styled.div`
	position: relative;
	display: flex;
	background: #3837ff;
	z-index: 1;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: row;
	border-radius: ${({ borderPosition }) =>
		borderPosition === "left" ? "16px 0 0 16px" : "0 16px 16px 0"};
	@media screen and (max-width: 820px) {
		flex-direction: column;
		border-radius: 16px;
		margin: 0;
		> * {
			margin-bottom: 32px;
			border-radius: 16px;
		}
	}

	@media screen and (min-width: 821px) {
		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 2000px;
			background: #3837ff;

			${({ borderPosition }) =>
				borderPosition === "left"
					? `
				right: -2000px;
			`
					: `
				left: -2000px;
			`}
		}
	}
`;

export const ServicesBody = styled.div`tya
	flex: 1;
	padding: 64px;
	@media screen and (max-width: 820px) {
		padding: 16px;
		margin: 16px;
	}
`;

export const ServicesIllustration = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	order: ${({ illustrationPosition }) =>
		illustrationPosition === "left" ? -1 : 0};

	> div {
		position: absolute;
		top: -100px;
		right: -200px;
		bottom: 16px;
		left: -200px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	@media screen and (max-width: 820px) {
		padding-top: 32px;
		> div {
			position: static;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
`;

export const Heading = styled.h1`
	text-align: center;
	margin: 10px;
	color: #000;
	margin-bottom: 24px;
	line-height: 1.1;
	color: #ffffff;
	@media screen and (max-width: 480px) {
	}
`;

export const Subtitle = styled.p`
	text-align: center;
	margin: 10px;
	margin-bottom: 35px;
	line-height: 24px;
	color: #ffffff;
`;
