import React from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Info from "../components/Info";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Partners from "../components/Partners";
import Features from "../components/Features";
import {
	homeObjOne,
	homeObjTwo,
	homeObjThree,
} from "../components/Services/Data";

const index = () => {
	return (
		<>
			<Navbar />
			<Header />
			<Info />
			<Services {...homeObjOne} index={0} />
			<Services {...homeObjTwo} index={1} />
			<Services {...homeObjThree} index={2} />
			<Features />
			<Partners />
			<Footer />
		</>
	);
};

export default index;
