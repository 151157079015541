import React from "react";
import appstore from "../../images/apple_white.svg";
import playstore from "../../images/playstore_white.svg";
import headerimg from "../../images/mockup_header.png";
import {
	HeaderContainer,
	HeaderItems,
	HeaderDownloads,
	HeaderIllustration,
} from "./HeaderElements";

const Header = () => {
	return (
		<>
			<HeaderContainer>
				<HeaderItems>
					<h1>De App voor Positieve Gezondheid</h1>

					<HeaderDownloads>
						<a
							href="https://apps.apple.com/nl/app/positieve-gezondheid/id1584255754?l=en"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={appstore} alt="" />
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=io.shardly.positievegezondheid"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={playstore} alt="" />
						</a>
					</HeaderDownloads>
				</HeaderItems>
				<HeaderItems>
					<HeaderIllustration>
						<img src={headerimg} alt="" />
					</HeaderIllustration>
				</HeaderItems>
			</HeaderContainer>
		</>
	);
};

export default Header;
