import React from "react";
import { InfoContainer, InfoBody, InfoIllustration } from "./InfoElements";
import illustrationFeatures from "../../images/illustration_features-blocks.svg";

const Info = () => {
	return (
		<InfoContainer>
			<InfoBody>
				<p>
					Het gedachtegoed van Positieve Gezondheid is populair door
					de integrale benadering van de definitie van gezondheid.
					Veel organisaties nemen dit mee in werkprocessen richting
					klant, patiënt, cliënt en bewoner.
				</p>
				<p>
					Om mensen goed te kunnen bereiken en zelf te laten werken
					met Positieve Gezondheid, is er tooling nodig. Dit is de
					eerste App voor Positieve Gezondheid.
				</p>
			</InfoBody>
			<InfoIllustration>
				<img
					src={illustrationFeatures}
					alt="Illustration showcasing the features of the app"
				/>
			</InfoIllustration>
		</InfoContainer>
	);
};

export default Info;
