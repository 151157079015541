import React from "react";

import { FeaturesContainer, FeaturesItem } from "./FeaturesElements";

const Features = () => {
	return (
		<>
			<FeaturesContainer>
				<FeaturesItem>
					<p>44 vragen, verdeeld over de 6 domeinen</p>
				</FeaturesItem>
				<FeaturesItem>
					<p>Inzicht in je score per domein</p>
				</FeaturesItem>
				<FeaturesItem>
					<p>Tips per domein om je score te verbeteren</p>
				</FeaturesItem>
				<FeaturesItem>
					<p>360 graden feedback</p>
				</FeaturesItem>
			</FeaturesContainer>
		</>
	);
};

export default Features;
