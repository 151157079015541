export const homeObjOne = {
	id: "about",
	headline: "Tips en Feedback",
	description:
		"Krijg tips over hoe je jouw score per domein kunt verbeteren. En ontvang feedback van anderen in jouw omgeving",
	illustrationPosition: "left",
	img: require("../../images/mockup_usp_1.png").default,
	alt: "Car",
	borderPosition: "right",
};
export const homeObjTwo = {
	id: "discover",
	headline: "Direct resultaten en advies",
	description:
		"Snel inizchtelijk hoe jij scoort op de zes domeinen van Positieve Gezondheid",
	illustrationPosition: "right",
	img: require("../../images/mockup_usp_2.png").default,
	alt: "Phonecard",
	borderPosition: "left",
};
export const homeObjThree = {
	id: "signup",
	headline: "360 Graden feedback",
	description:
		"Het zal nog geen minuut van uw tijd kosten en daarna kunt u direct beginnen met mensen ",
	illustrationPosition: "left",
	img: require("../../images/mockup_usp_3.png").default,
	alt: "Signup",
	borderPosition: "right",
};
