import styled from "styled-components";

export const HeaderContainer = styled.header`
	max-width: 1024px;
	margin: auto;
	background: #3837ff;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	align-self: center;
	margin: auto;
	border-radius: 16px;

	@media screen and (max-width: 820px) {
		width: 90%;
	}
`;

export const HeaderItems = styled.div`
	text-align: left;
	padding: 5px;
	width: 400px;
	height: 100%;
	margin: 10px;
	color: white;

	@media screen and (max-width: 820px) {
		text-align: center;
	}
`;

export const HeaderDownloads = styled.div`
	display: flex;
	padding-right: 5px;
	height: 48px;
	img {
		height: 32px;
		margin-right: 16px;
	}
	@media screen and (max-width: 820px) {
		padding-bottom: 16px;
		display: flex;
		justify-content: center;
		img {
			height: 32px;
		}
	}
`;

export const HeaderIllustration = styled.div`
	display: flex;
	height: 550px;
	justify-content: center;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media screen and (max-width: 820px) {
		height: 300px;
	}
`;
