import React from "react";
import {
	ServicesContainer,
	ServicesWrapper,
	Heading,
	Subtitle,
	ServicesBody,
	ServicesIllustration,
} from "./ServicesElements";

const Services = ({
	id,
	illustrationPosition,
	headline,
	description,
	img,
	alt,
	borderPosition,
	index,
}) => {
	return (
		<ServicesContainer
			id={id}
			borderPosition={borderPosition}
			index={index}
		>
			<ServicesWrapper borderPosition={borderPosition}>
				<ServicesBody>
					<Heading>{headline}</Heading>
					<Subtitle>{description}</Subtitle>
				</ServicesBody>
				<ServicesIllustration
					illustrationPosition={illustrationPosition}
				>
					<div>
						<img src={img} alt={alt} />
					</div>
				</ServicesIllustration>
			</ServicesWrapper>
		</ServicesContainer>
	);
};

export default Services;
